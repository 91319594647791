/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8cd0ccb0-5bd6-4d12-9601-a5a4436a3236",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_eEHhyC86P",
    "aws_user_pools_web_client_id": "5hmbsen5l7utk0hq7j00utdc47",
    "oauth": {}
};


export default awsmobile;
